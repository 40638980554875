define("discourse/plugins/discourse-category-images/discourse/initializers/init-category-images", ["exports", "@ember/template", "discourse/lib/plugin-api", "discourse/lib/text", "I18n", "discourse-common/lib/get-url", "discourse/lib/hashtag-types/category", "@ember/service", "discourse-common/lib/helpers", "discourse/lib/utilities", "discourse-common/lib/icon-library", "@ember/object", "discourse/models/category"], function (_exports, _template, _pluginApi, _text, _I18n, _getUrl, _category, _service, _helpers, _utilities, _iconLibrary, _object, _category2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CategoryHashtagTypeWithIconImage extends _category.default {
    constructor(dict, owner) {
      super(owner);
      this.dict = dict;
    }
    generateIconHTML(hashtag) {
      const iconImageURL = this.dict[hashtag.id];
      if (iconImageURL) {
        return `<img src="${iconImageURL}">`;
      } else {
        return super.generateIconHTML(hashtag);
      }
    }
  }
  var _default = _exports.default = {
    name: "category-images",
    initialize(container) {
      (0, _pluginApi.withPluginApi)("1.2.0", api => {
        const site = container.lookup("site:main");
        const categories = site.get("categoriesList");
        const dict = {};
        categories.forEach(category => {
          if (category.custom_fields?.icon_upload_url) {
            const opts = {
              categoryId: category.id,
              prefixType: "image",
              prefixValue: category.custom_fields?.icon_upload_url,
              prefixColor: category.color
            };

            // sidebar
            api.registerCustomCategorySectionLinkPrefix(opts);

            // prepare hashtagtype dict
            dict[category.id] = category.custom_fields?.icon_upload_url;
          }
        });

        // set hashtagtype dict
        api.registerHashtagType("category", new CategoryHashtagTypeWithIconImage(dict, container));

        //  renderer helper functions

        function buildTopicCount(count) {
          return `<span class="topic-count" aria-label="${_I18n.default.t("category_row.topic_count", {
            count
          })}">&times; ${count}</span>`;
        }

        // new category link renderer
        function categoryImagesRenderer(category, opts) {
          let siteSettings = (0, _helpers.helperContext)().siteSettings;
          let descriptionText = (0, _utilities.escapeExpression)((0, _object.get)(category, "description_text"));
          let restricted = (0, _object.get)(category, "read_restricted");
          let url = opts.url ? opts.url : (0, _getUrl.default)(`/c/${_category2.default.slugFor(category)}/${(0, _object.get)(category, "id")}`);
          let href = opts.link === false ? "" : url;
          let tagName = opts.link === false || opts.link === "false" ? "span" : "a";
          let extraClasses = opts.extraClasses ? " " + opts.extraClasses : "";
          let html = "";
          let parentCat = null;
          let categoryDir = "";
          let dataAttributes = category ? `data-category-id="${(0, _object.get)(category, "id")}"` : "";

          /// Add custom category icon image
          let iconImageURL = category.custom_fields?.icon_upload_url;
          if (!opts.hideParent) {
            parentCat = _category2.default.findById((0, _object.get)(category, "parent_category_id"));
          }
          let classNames = `badge-category ${iconImageURL ? "--has-icon-image" : ""}`;
          if (restricted) {
            classNames += " restricted";
          }
          if (parentCat) {
            classNames += ` --has-parent`;
            dataAttributes += ` data-parent-category-id="${parentCat.id}"`;
          }
          html += `<span
          ${dataAttributes}
          data-drop-close="true"
          class="${classNames}"
          ${descriptionText ? 'title="' + descriptionText + '" ' : ""}
        >`;
          if (iconImageURL) {
            html += `<span  class="badge-category__image"><img src="${iconImageURL}"></span>`;
          }
          /// End custom category icon image

          let categoryName = (0, _utilities.escapeExpression)((0, _object.get)(category, "name"));
          if (siteSettings.support_mixed_text_direction) {
            categoryDir = isRTL(categoryName) ? 'dir="rtl"' : 'dir="ltr"';
          }
          if (restricted) {
            html += (0, _iconLibrary.iconHTML)("lock");
          }
          html += `<span class="badge-category__name" ${categoryDir}>${categoryName}</span>`;
          html += "</span>";
          if (opts.topicCount) {
            html += buildTopicCount(opts.topicCount);
          }
          if (href) {
            href = ` href="${href}" `;
          }
          let afterBadgeWrapper = "";
          if (opts.plusSubcategories && opts.lastSubcategory) {
            afterBadgeWrapper += `<span class="plus-subcategories">
            ${_I18n.default.t("category_row.plus_subcategories", {
              count: opts.plusSubcategories
            })}
            </span>`;
          }
          return `<${tagName} class="badge-category__wrapper ${extraClasses}" ${href}>${html}</${tagName}>${afterBadgeWrapper}`;
        }
        api.replaceCategoryLinkRenderer(categoryImagesRenderer);
      });
    }
  };
});