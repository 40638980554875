define("discourse/plugins/discourse-category-images/discourse/connectors/category-custom-settings/category-images", ["exports", "@ember/object", "@glimmer/tracking", "@ember/component", "discourse-common/helpers/i18n", "discourse/components/uppy-image-uploader", "@ember/template-factory"], function (_exports, _object, _tracking, _component, _i18n, _uppyImageUploader, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CategoryImages extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "iconImageUrl", [_tracking.tracked]))();
    #iconImageUrl = (() => (dt7948.i(this, "iconImageUrl"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "aspectWarning", [_tracking.tracked], function () {
      return false;
    }))();
    #aspectWarning = (() => (dt7948.i(this, "aspectWarning"), void 0))();
    init() {
      super.init(...arguments);
      this.iconImageUrl = this.outletArgs.category.custom_fields.icon_upload_url;
    }
    iconUploadDone(upload1) {
      this.aspectWarning = Math.max(upload1.height, upload1.width) / Math.min(upload1.height, upload1.width) > 1.1;
      this.iconImageUrl = upload1.url;
      this.outletArgs.category.custom_fields.icon_upload_url = upload1.url;
      this.outletArgs.category.custom_fields.icon_upload_id = upload1.id;
    }
    static #_3 = (() => dt7948.n(this.prototype, "iconUploadDone", [_object.action]))();
    iconUploadDeleted() {
      this.iconImageUrl = null;
      this.outletArgs.category.custom_fields.icon_upload_url = null;
      this.outletArgs.category.custom_fields.icon_upload_id = null;
    }
    static #_4 = (() => dt7948.n(this.prototype, "iconUploadDeleted", [_object.action]))();
    static #_5 = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <section class="field category-icon">
          <label>{{i18n "category_creator.category_icon"}}</label>
          <UppyImageUploader
            @imageUrl={{this.iconImageUrl}}
            @onUploadDone={{this.iconUploadDone}}
            @onUploadDeleted={{this.iconUploadDeleted}}
            @type="category_icon"
            @id="category-icon-uploader"
            class="no-repeat contain-image"
          />
          {{#if this.aspectWarning}}
            <label class="aspect-ratio-warning">{{i18n "category_creator.aspect_ratio_warning"}}</label>
          {{/if}}
        </section>
      
    */
    {
      "id": "J199pt8T",
      "block": "[[[1,\"\\n    \"],[10,\"section\"],[14,0,\"field category-icon\"],[12],[1,\"\\n      \"],[10,\"label\"],[12],[1,[28,[32,0],[\"category_creator.category_icon\"],null]],[13],[1,\"\\n      \"],[8,[32,1],[[24,0,\"no-repeat contain-image\"]],[[\"@imageUrl\",\"@onUploadDone\",\"@onUploadDeleted\",\"@type\",\"@id\"],[[30,0,[\"iconImageUrl\"]],[30,0,[\"iconUploadDone\"]],[30,0,[\"iconUploadDeleted\"]],\"category_icon\",\"category-icon-uploader\"]],null],[1,\"\\n\"],[41,[30,0,[\"aspectWarning\"]],[[[1,\"        \"],[10,\"label\"],[14,0,\"aspect-ratio-warning\"],[12],[1,[28,[32,0],[\"category_creator.aspect_ratio_warning\"],null]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-category-images/discourse/connectors/category-custom-settings/category-images.js",
      "scope": () => [_i18n.default, _uppyImageUploader.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = CategoryImages;
});